import { ReactComponent as EmailIcon } from '@aphrodite/assets/icons/email-colored.svg';
import { ReactComponent as GridIcon } from '@aphrodite/assets/icons/grid.svg';

export interface INavItem {
  to: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: string;
}

export const NAV_ITEMS: { [key: string]: INavItem } = {
  DASHBOARD: {
    to: '/dashboard',
    Icon: GridIcon,
    text: 'Dashboard',
  },
  SINGLE_SENDS: {
    to: '/single-sends',
    Icon: EmailIcon,
    text: 'Single Sends',
  },
};
