import { Navigate, useLocation } from 'react-router-dom';

import { useAuthHelper } from '../hooks/useAuthHelper';

interface Props {
  children: JSX.Element;
}
export default function RequireAuth({ children }: Props): JSX.Element {
  const { user } = useAuthHelper();
  const location = useLocation();
  if (!user) {
    return (
      <Navigate
        to={{
          pathname: '/start',
        }}
        state={{ from: location }}
      />
    );
  }
  return children;
}
