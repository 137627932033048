import { getAnalytics, setUserId } from 'firebase/analytics';
import {
  User,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  onAuthStateChanged,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';

import { firebaseFunctions } from '@aphrodite/firebase/firebase';
import { firebaseAuth } from '@aphrodite/firebase/firebase';

import PageLoading from '../pages/PageLoading';
import createStrictContext from './createStrictContext';

interface IAuthContext {
  user: User | null | undefined;
  isAuthenticating: boolean;
  sendSignInLinkToEmail: (email: string) => Promise<boolean>;
  signInWithEmailLink: (code: string) => Promise<boolean>;
  logout: () => Promise<void>;
}

const EMAIL_LOCAL_STORAGE = 'emailForSignIn';
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const [AuthContextProvider, useAuthHelper] = createStrictContext<IAuthContext>();
export { useAuthHelper };

/**
 * Wrapper hook for all firebase authentication related matters. Provider hook that creates auth object and handles state.
 *
 * By default, the user session will persist across sessions until explicit logout.
 * See https://firebase.google.com/docs/auth/web/auth-state-persistence.
 */
type Props = {
  children?: React.ReactNode;
};
export const AuthHelperProvider: React.FC<Props> = ({ children }) => {
  const analytics = getAnalytics();
  const [user, setUser] = useState<User | null>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const sendSignInLinkToEmail = async (email: string): Promise<boolean> => {
    return httpsCallable(
      firebaseFunctions,
      'internalSendSignInLinkToEmail',
    )({
      email,
      origin: window.location.origin,
    })
      .then((_) => {
        window.localStorage.setItem(EMAIL_LOCAL_STORAGE, email);
        return true;
      })
      .catch((error) => {
        return true;
      });
  };
  const signInWithEmailLink = async (code: string): Promise<boolean> => {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem(EMAIL_LOCAL_STORAGE);
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // Client SDK will parse code from the link for us.
    return firebaseSignInWithEmailLink(firebaseAuth, email ? email : '', window.location.href).then(
      (result) => {
        // Clean up email from storage.
        window.localStorage.removeItem(EMAIL_LOCAL_STORAGE);
        setUser(result.user);
        setUserId(analytics, result.user!.uid);
        return true;
      },
    );
  };
  const logout = async (): Promise<void> => {
    return firebaseAuth.signOut().then(() => {
      setUser(null);
    });
  };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setUser(user);
      setIsAuthenticating(false);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  });
  const values = {
    user,
    isAuthenticating,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    logout,
  };
  if (isAuthenticating) {
    return <PageLoading />;
  }
  return <AuthContextProvider value={values}>{!isAuthenticating && children}</AuthContextProvider>;
};
