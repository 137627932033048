export enum CollegesEnum {
  // USA
  UCLA = 'ucla',
  HARVARD = 'harvard',
  MCMASTER = 'mcmaster',
  MIT = 'mit',
  CMU = 'cmu',
  // Singapore
  NTU = 'ntu',
  NUS = 'nus',
  SMU = 'smu',
  // Canada
  UBC = 'ubc',
  UOFT = 'uoft',
  UWATERLOO = 'uwaterloo',
  WESTERN = 'western',
  // Hong Kong
  HKU = 'hku',
}
export const COLLEGE_FULL_NAMES: { [key in CollegesEnum]: string } = {
  [CollegesEnum.NTU]: 'Nanyang Technological University',
  [CollegesEnum.NUS]: 'National University of Singapore',
  [CollegesEnum.SMU]: 'Singapore Management University',
  // CANADA
  [CollegesEnum.WESTERN]: 'Western University',
  [CollegesEnum.UWATERLOO]: 'University of Waterloo',
  [CollegesEnum.UOFT]: 'University of Toronto',
  [CollegesEnum.UBC]: 'University of British Columbia',
  // USA
  [CollegesEnum.UCLA]: 'University of California, Los Angeles',
  [CollegesEnum.HARVARD]: 'Harvard University',
  [CollegesEnum.MCMASTER]: 'McMaster University',
  [CollegesEnum.MIT]: 'Massachusetts Institute of Technology',
  [CollegesEnum.CMU]: 'Carnegie Mellon University',
  // Hong Kong
  [CollegesEnum.HKU]: 'Hong Kong University',
};
export const REGION_UTC_OFFSET: { [key in string]: number } = {
  NORTH_AMERICA: -5,
  SINGAPORE: 8,
  HONG_KONG: 8,
};
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DISABLED_COLLEGES = [CollegesEnum.NUS, CollegesEnum.NTU, CollegesEnum.SMU];
export const COLLEGE_DOMAINS_ALLOWLIST_REGEX: { [key in CollegesEnum]: RegExp[] } = {
  // Singapore
  // e0199467@u.nus.edu a0199467@u.nus.edu
  nus: [/^[e|a]([0-9]+)@u.nus.edu$/, /^cupids@aphrodite.global$/],
  // <anything>@e.ntu.edu.sg
  ntu: [/^([\w.-]+)@e.ntu.edu.sg$/, /^cupids@aphrodite.global$/],
  // <anything>@<anything>.smu.edu.sg
  smu: [/^([\w.-]+)@([\w.-]+).smu.edu.sg$/, /^cupids@aphrodite.global$/],
  // Canada
  western: [/^([\w.-]+)@uwo.ca$/, /^([\w.-]+)@ivey.ca$/],
  uoft: [/^([\w.-]+)@mail.utoronto.ca$/, /^([\w.-]+)@utoronto.ca$/],
  uwaterloo: [/^([\w.-]+)@uwaterloo.ca$/],
  ubc: [
    /^([\w.-]+)@student.ubc.ca$/,
    /^([\w.-]+)@alum.ubc.ca$/,
    /^([\w.-]+)@alumni.ubc.ca$/,
    /^([\w.-]+)@mail.ubc.ca$/,
  ],
  mcmaster: [/^([\w.-]+)@mcmaster.ca$/],
  // USA
  ucla: [/^([\w.-]+)@g.ucla.edu$/, /^([\w.-]+)@ucla.edu$/, /^([\w.-]+)@alumni.ucla.edu$/],
  mit: [/^([\w.-]+)@mit.edu$/, /^([\w.-]+)@alum.mit.edu$/],
  harvard: [
    /^([\w.-]+)@([\w\-.]+.)?harvard.edu$/,
    /^([\w.-]+)@hbs.edu$/,
    /^([\w.-]+)@([\w\-.]+.)?hbs.edu$/,
  ],
  cmu: [/^([\w.-]+)@andrew.cmu.edu$/, /^([\w.-]+)@cmu.edu$/, /^([\w.-]+)@alumni.cmu.edu$/],
  // HKU
  hku: [/^([\w.-]+)@connect.hku.hk$/, /^([\w.-]+)@graduate.hku.hk$/],
};
export const COLLEGE_COLORS: { [key in CollegesEnum]: any } = {
  // Singapore
  nus: {
    default: {
      background: 'rgba(239, 124, 0, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(239, 124, 0, 0.85)',
      text: 'white',
    },
  },
  ntu: {
    default: {
      background: 'rgba(0, 52, 120, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(0, 52, 120, 0.85)',
      text: 'white',
    },
  },
  smu: {
    default: {
      background: 'rgba(21, 28, 85, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(21, 28, 85, 0.85)',
      text: 'white',
    },
  },
  // Hong Kong
  hku: {
    default: {
      background: 'rgba(0, 100, 0, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(0, 100, 0, 0.85)',
      text: 'white',
    },
  },
  // Canada
  western: {
    default: {
      background: 'rgba(79, 38, 131, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(79, 38, 131, 0.85)',
      text: 'white',
    },
  },
  uwaterloo: {
    default: {
      background: 'rgba(248, 213, 103, 1)',
      text: 'black',
    },
    hoverIn: {
      background: 'rgba(248, 213, 103, 0.85)',
      text: 'white',
    },
  },
  uoft: {
    default: {
      background: 'rgba(15, 47, 97, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(15, 47, 97, 0.85)',
      text: '#E2E2E2',
    },
  },
  ubc: {
    default: {
      background: 'rgba(18, 29, 62, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(18, 29, 62, 0.85)',
      text: '#E2E2E2',
    },
  },
  mcmaster: {
    default: {
      background: 'rgba(97, 16, 48, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(97, 16, 48, 0.85)',
      text: '#E2E2E2',
    },
  },
  // USA
  ucla: {
    default: {
      background: 'rgba(53, 124, 189, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(53, 124, 189, 0.85)',
      text: '#E2E2E2',
    },
  },
  mit: {
    default: {
      background: 'rgba(163, 31, 52, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(163, 31, 52, 0.85)',
      text: '#E2E2E2',
    },
  },
  harvard: {
    default: {
      background: 'rgba(165, 28, 48, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(165, 28, 48, 0.85)',
      text: '#E2E2E2',
    },
  },
  cmu: {
    default: {
      background: 'rgba(196, 18, 48, 1)',
      text: 'white',
    },
    hoverIn: {
      background: 'rgba(196, 18, 48, 0.85)',
      text: 'white',
    },
  },
};
// This is only for display. Remember to set in the cloud function as well.
export const COLLEGE_ALLOWLIST_DOMAINS: { [key in CollegesEnum]: string[] } = {
  nus: ['e1234567@u.nus.edu', 'a1234567@u.nus.edu'],
  ntu: ['userid@e.ntu.edu.sg'],
  smu: ['userid@<faculty>.smu.edu.sg'],
  western: ['@uwo.ca', '@ivey.ca'],
  uoft: ['@mail.utoronto.ca', '@utoronto.ca'],
  uwaterloo: ['@uwaterloo.ca'],
  ubc: ['@student.ubc.ca', '@alum.ubc.ca', '@alumni.ubc.ca', '@mail.ubc.ca'],
  mcmaster: ['@mcmaster.ca'],
  ucla: ['@g.ucla.edu', '@ucla.edu', '@alumni.ucla.edu'],
  mit: ['@mit.edu', '@alum.mit.edu'],
  harvard: ['@harvard.edu', '@<fac>.harvard.edu', '@hbs.edu', '@<class>.hbs.edu'],
  cmu: ['@andrew.cmu.edu', '@cmu.edu', '@alumni.cmu.edu'],
  hku: ['@connect.hku.hk', '@graduate.hku.hk'],
};
export const COLLEGE_EMAILS: { [key in CollegesEnum]: string } = {
  nus: '@u.nus.edu',
  ntu: '@e.ntu.edu.sg',
  smu: '@faculty.smu.edu.sg',
  uoft: '@mail.utoronto.ca',
  western: '@uwo.ca',
  uwaterloo: '@uwaterloo.ca',
  ubc: '@student.ubc.ca',
  mcmaster: '@mcmaster.ca',
  ucla: '@g.ucla.edu',
  mit: '@mit.edu',
  harvard: '@harvard.edu',
  cmu: '@andrew.cmu.edu',
  hku: '@connect.hku.hk',
};
export const COLLEGE_NAMES: { [key in CollegesEnum]: string } = {
  nus: 'NUS',
  ntu: 'NTU',
  smu: 'SMU',
  western: 'Western',
  uoft: 'UofT',
  uwaterloo: 'UWaterloo',
  ubc: 'UBC',
  mcmaster: 'McMaster',
  ucla: 'UCLA',
  mit: 'MIT',
  harvard: 'Harvard',
  cmu: 'CMU',
  hku: 'HKU',
};
const COLLEGE_CONSTANTS = {
  EMAIL_REGEX,
  COLLEGE_COLORS,
  COLLEGE_ALLOWLIST_DOMAINS,
  COLLEGE_DOMAINS_ALLOWLIST_REGEX,
  COLLEGE_EMAILS,
  COLLEGE_NAMES,
  DISABLED_COLLEGES,
};

export default COLLEGE_CONSTANTS;
