import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as LogoutIcon } from '@aphrodite/assets/icons/logout.svg';
import apLogo from '@aphrodite/assets/logos/ap.png';
import { ReactComponent as CancelIcon } from '@aphrodite/assets/logos/cancel.svg';
import { ReactComponent as MenuIcon } from '@aphrodite/assets/logos/menu.svg';
import { ADSText } from '@aphrodite/common-ui';

import { INavItem, NAV_ITEMS } from '../../constants/Navigation';

// Styled Components
const StyledContainer = styled.div<{ isNavDarkened: boolean }>`
  background-color: ${({ isNavDarkened }) =>
    isNavDarkened ? 'rgba(255, 255, 255, 0.75)' : undefined};
  padding: 4% 0;
  position: fixed;
  top: 0;
  transition: background-color 0.75s ease-in-out;
  width: 100%;
  z-index: 100;
`;
const StyledNavBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 8%;
  padding-right: 8%;
  position: relative;
  &:focus {
    outline: none;
  }
`;
const StyledNavBrand = styled(NavLink)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-decoration: none;
`;

const StyledLogo = styled.img`
  height: 1.5em;
  margin-right: 12px;
  @media only screen and (max-width: 680px) {
    height: 1.3em;
  }
`;
const StyledNavMenuContainer = styled.div<{ isNavCollapsed: boolean }>`
  background: white;
  border-radius: 8px;
  height: 90%;
  margin: 0 5%;
  min-width: 100px;
  opacity: ${({ isNavCollapsed }) => (isNavCollapsed ? 0 : 1)};
  padding-left: 4%;
  pointer-events: ${({ isNavCollapsed }) => (isNavCollapsed ? 'none' : 'auto')};
  position: fixed;
  top: 1%;
  transition: opacity 0.2s;
  width: 90%;
  z-index: 1000;
`;
const StyledCloseMenuContainer = styled.div`
  display: flex;
  margin-top: 6%;
  padding-right: 8%;
`;
const StyledCloseIcon = styled(CancelIcon)`
  margin-left: auto;
`;
const StyledMenuItem = styled(MenuItem)`
  position: absolute;
  bottom: 0;
`;
const StyledNavItems = styled.nav`
  align-items: flex-start;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;
const StyledNavToggle = styled.nav`
  align-items: center;
  align-self: flex-end;
  background-color: hsla(0, 0%, 0%, 0.05);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0 16px;
`;

const HEIGHT_LIMIT = 4;

//Main Component
export default function DashboardMenuBarMobile(): React.ReactElement {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleToggleClick = () => setIsNavCollapsed(!isNavCollapsed);
  const [isNavDarkened, setIsNavDarkened] = useState(false);
  // Darken nav background on scroll
  useEffect(() => {
    const handleScroll = () => {
      const shouldApplyBackground = window.pageYOffset > HEIGHT_LIMIT;
      if (shouldApplyBackground && !isNavDarkened) {
        setIsNavDarkened(true);
        return;
      }
      setIsNavDarkened(false);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledContainer isNavDarkened={isNavDarkened}>
      <StyledNavBar>
        <StyledNavBrand to="/dashboard">
          <StyledLogo src={apLogo} alt="Aphrodite logo" />
        </StyledNavBrand>
        <StyledNavToggle onClick={handleToggleClick}>
          <MenuIcon width="1em" height="1em" />
        </StyledNavToggle>
      </StyledNavBar>
      <StyledNavMenuContainer
        onBlur={() => {
          setIsNavCollapsed(true);
        }}
        tabIndex={0}
        isNavCollapsed={isNavCollapsed}
      >
        <StyledCloseMenuContainer onClick={() => setIsNavCollapsed(true)}>
          <StyledCloseIcon width="1em" height="1em" />
        </StyledCloseMenuContainer>
        <StyledNavItems>
          {Object.keys(NAV_ITEMS).map((key: string, _: number) => {
            const { to, Icon, text } = NAV_ITEMS[key];
            return (
              <MenuItem
                key={text}
                to={to}
                Icon={Icon}
                onClick={() => setIsNavCollapsed(true)}
                text={text}
              />
            );
          })}
        </StyledNavItems>
        <StyledMenuItem Icon={LogoutIcon} text="Logout" to="/logout" />
      </StyledNavMenuContainer>
    </StyledContainer>
  );
}

const StyledIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  line-height: 60px;
  &:hover {
    opacity: 0.75;
  }
`;
const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin: 8px 0;
  padding-right: 0;
  width: 100%;
  &:hover {
    opacity: 0.75;
  }
`;

interface MenuItemProps extends INavItem {
  className?: string;
  onClick?: () => void;
}

function MenuItem({ className, to, Icon, onClick, text }: MenuItemProps): React.ReactElement {
  return (
    <StyledNavLink className={className} onClick={onClick} to={to}>
      <StyledIconContainer>
        <Icon height={20} width={20} fill="#151515" />
      </StyledIconContainer>
      <ADSText size="m">{text}</ADSText>
    </StyledNavLink>
  );
}
