import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { ThemeProvider } from 'styled-components';

import theme from '@aphrodite/common-ui/AphroditeTheme';
import GlobalStyle from '@aphrodite/common-ui/globalStyles';
import { firebaseConfig } from '@aphrodite/firebase/firebase';

import { AuthHelperProvider } from './hooks/useAuthHelper';
import Root from './pages/Root';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AuthHelperProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <GlobalStyle />
            <Root />
          </BrowserRouter>
        </ThemeProvider>
      </AuthHelperProvider>
    </FirebaseAppProvider>
  </React.StrictMode>,
);
