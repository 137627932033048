import { AttrCount, UniData } from '@aphrodite/common/types/DataReportTypes';

/**
 *
 * @param selectedUniData Selected university data
 * @param key Key to convert to graph format
 * @returns Graph format to visualize, in ascending order
 */
export function objectDataToGraphFormat(selectedUniData: UniData, key: keyof UniData) {
  if (!(key in selectedUniData)) {
    return [];
  }
  return Object.keys(selectedUniData[key])
    .map((name: any) => {
      const count = (selectedUniData[key] as AttrCount)[name];
      return {
        name,
        count,
      };
    })
    .sort((a, b) => b['count'] - a['count']);
}
