import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as LogoutIcon } from '@aphrodite/assets/icons/logout.svg';
import bigBg from '@aphrodite/assets/images/large-bg.jpg';
import APLogo from '@aphrodite/assets/logos/ap.png';

import { NAV_ITEMS } from '../../constants/Navigation';

const StyledContainer = styled.nav`
  background: white;
  border-radius: 0 10px 10px 0;
  bottom: 0;
  box-sizing: initial;
  left: 0;
  position: fixed;
  top: 0;
  width: ${({ theme }) => theme.spacing.navbarDesktopWidth};
`;
const StyledImageContainer = styled.div`
  align-items: center;
  background-color: #474957;
  display: flex;
  height: ${({ theme }) => theme.spacing.navbarDesktopLogoHeight};
  justify-content: center;
`;
const StyledList = styled.ul`
  overflow: visible;
  padding-left: 10px;
  position: absolute;
  top: ${({ theme }) => theme.spacing.navbarDesktopLogoHeight};
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`;
const StyledLogo = styled.img`
  height: 2.5em;
  margin-right: 12px;
  @media only screen and (max-width: 680px) {
    height: 1.5em;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  padding-left: 10px;
  position: absolute !important;
  bottom: 16px;
`;
const StyledNavBrand = styled(NavLink)`
  cursor: pointer;
`;

function isNavItemActive(pathname: string, to: string) {
  if (to === NAV_ITEMS.DASHBOARD.to) {
    return pathname === NAV_ITEMS.DASHBOARD.to;
  }
  if (pathname.includes('match_profile') && to === NAV_ITEMS.RESULTS.to) {
    return true;
  }
  return pathname.includes(to);
}
export default function DashboardMenuBarDesktop(): React.ReactElement {
  const pathname = useLocation().pathname;
  return (
    <StyledContainer>
      <StyledNavBrand to="/dashboard">
        <StyledImageContainer>
          <StyledLogo alt="aphrodite logo" src={APLogo} />
        </StyledImageContainer>
      </StyledNavBrand>
      <StyledList>
        {Object.keys(NAV_ITEMS).map((key: string, index: number) => {
          const { to, Icon, text } = NAV_ITEMS[key];
          return (
            <MenuItem
              key={text}
              Icon={Icon}
              isActive={isNavItemActive(pathname, to)}
              position={index}
              text={text}
              to={to}
            />
          );
        })}
      </StyledList>
      <StyledMenuItem Icon={LogoutIcon} text={'Logout'} to={'/logout'} />
    </StyledContainer>
  );
}

const ActiveItemMixin = css`
  background: transparent;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  @media only screen and (min-width: 680px) {
    &:before {
      background: url(${bigBg}) no-repeat center center fixed;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -10;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`;
const StyledCurveUp = styled.div<{ isFirstItem: boolean }>`
  background: #000;
  content: '';
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: -20px;
  transform: rotate(180deg);
  width: 20px;
  z-index: 100;
  :before {
    content: '';
    position: absolute;
    height: 150%;
    width: 150%;
    border-radius: 70% 0 0 0;
    background: ${({ isFirstItem }) => (isFirstItem ? '#474957' : 'white')};
  }
  @media only screen and (min-width: 680px) {
    :after {
      background: url(${bigBg}) no-repeat center center fixed;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -10;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`;
const StyledCurveDown = styled.div`
  background: #000;
  content: '';
  height: 20px;
  position: absolute;
  pointer-events: none;
  right: 0px;
  top: 60px;
  transform: rotate(90deg);
  width: 20px;
  z-index: 100;
  :before {
    background: white;
    border-radius: 70% 0 0 0;
    content: '';
    position: absolute;
    height: 150%;
    width: 150%;
  }
  @media only screen and (min-width: 680px) {
    :after {
      background: url(${bigBg}) no-repeat center center fixed;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -10;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`;
const StyledListItem = styled.li<{ isActive: boolean }>`
  background: white;
  list-style: none;
  position: relative;
  width: 100%;
  a {
    color: black;
    display: flex;
    flex-direction: row;
    position: relative;
    text-decoration: none;
    width: 100%;
    &:hover,
    &:focus {
      outline: none;
    }
  }
  ${(props) => (props.isActive ? ActiveItemMixin : undefined)};
  &:hover,
  &:focus {
    outline: none;
  }
`;
const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  line-height: 60px;
  min-width: 60px;
  position: relative;
`;
const StyledTextSpan = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#151515' : 'grey')};
  display: block;
  height: 60px;
  line-height: 60px;
  padding-left: 10px;
  position: relative;
  text-align: center;
  white-space: normal;
`;

interface MenuItemProps {
  className?: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  isActive?: boolean;
  position?: number;
  text: string;
  to: string;
}

function MenuItem({
  className,
  Icon,
  isActive = false,
  position,
  text,
  to,
}: MenuItemProps): React.ReactElement {
  return (
    <StyledListItem className={className} isActive={isActive}>
      {isActive && <StyledCurveUp isFirstItem={position !== undefined && position === 0} />}
      <NavLink to={to}>
        <StyledIconContainer>
          <Icon height={20} width={20} fill={isActive ? '#151515' : 'grey'} />
        </StyledIconContainer>
        <StyledTextSpan isActive={isActive}>{text}</StyledTextSpan>
      </NavLink>
      {isActive && <StyledCurveDown />}
    </StyledListItem>
  );
}
