import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import styled from 'styled-components';

import { ADSHeading } from '@aphrodite/common-ui';
import { emulatorConfig } from '@aphrodite/firebase/emulators/firebaseEmulatorConfiguration';
import { firebaseFunctions } from '@aphrodite/firebase/firebase';

const StyledContentsContainer = styled.main`
  display: flex;
  max-width: 100%;
  padding: 0 8%;
  padding-top: 20%;
  padding-bottom: 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
    grid-template-columns: 3fr 2fr;
  }
`;

export default function DashboardSingleSends(): React.ReactElement {
  connectFunctionsEmulator(firebaseFunctions, 'localhost', emulatorConfig.functions);
  const getTemplates = httpsCallable(firebaseFunctions, 'getAllEmailTemplates');
  getTemplates().then((res) => {
    console.log(res);
  });
  return (
    <StyledContentsContainer>
      <ADSHeading level="2">Single Sends</ADSHeading>
    </StyledContentsContainer>
  );
}
