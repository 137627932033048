import { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import apLogo from '@aphrodite/assets/logos/ap.png';
import cancel from '@aphrodite/assets/logos/cancel.svg';
import menu from '@aphrodite/assets/logos/menu.svg';
import { ADSAnchor, ADSText } from '@aphrodite/common-ui';
import { getAnalytics, logEvent } from '@firebase/analytics';

// Styled Components
const StyledNavBar = styled.div`
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5%;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledNavBrand = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

const StyledLogo = styled.img`
  height: 1.5em;
  margin-right: 12px;
  @media only screen and (max-width: 680px) {
    height: 1.3em;
  }
`;
const StyledLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding-right: 36px;
  &:hover {
    opacity: 0.75;
  }
  @media only screen and (max-width: 680px) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 8px 0;
    padding-right: 0;
    margin-left: auto;
  }
`;
const StyledText = styled(ADSText)`
  @media only screen and (min-width: 680px) and (max-width: 900px) {
    font-size: 12px;
  }
`;
const StyledIcon = styled.img`
  width: 1em;
  height: 1em;
`;
const StyledNavItems = styled.nav<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${StyledLink}:last-child {
    padding-right: 0;
  }
  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    margin-top: 8px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s;
    flex-basis: 100%;
    margin-left: auto;
    ${(props) => {
      if (!props.collapsed) {
        return css`
          max-height: 300px;
          z-index: 11;
          transition: all 1s;
          margin-left: auto;
        `;
      }
    }}
  }
`;

const StyledNavToggle = styled.nav`
  display: none;
  @media only screen and (max-width: 680px) {
    align-self: flex-end;
    display: initial;
    cursor: pointer;
  }
`;

//Main Component
const Navbar: FunctionComponent<{}> = () => {
  const analytics = getAnalytics();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleToggleClick = () => setIsNavCollapsed(!isNavCollapsed);
  const collapseNav = () => {
    setIsNavCollapsed(true);
  };
  return (
    <StyledNavBar onBlur={collapseNav} tabIndex={0}>
      <StyledNavBrand to="/">
        <StyledLogo src={apLogo} alt="Aphrodite logo" />
      </StyledNavBrand>
      <StyledNavToggle onClick={handleToggleClick}>
        <StyledIcon src={isNavCollapsed ? menu : cancel} alt="menu" />
      </StyledNavToggle>
      <StyledNavItems collapsed={isNavCollapsed}>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to="mailto: cupids@aphrodite.global"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'contact_us',
              })
            }
          >
            <StyledText size="s">Contact Us</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to="https://bit.ly/aphropolicy2022"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'privacy_policy',
              })
            }
          >
            <StyledText size="s">Privacy Policy</StyledText>
          </ADSAnchor>
        </StyledLink>
        <StyledLink>
          <ADSAnchor
            target="_blank"
            to="https://bit.ly/aphrofaq2022"
            onClick={() =>
              logEvent(analytics, 'link-click', {
                component: 'NAVBAR',
                button_name: 'faqs',
              })
            }
          >
            <StyledText size="s">FAQs</StyledText>
          </ADSAnchor>
        </StyledLink>
      </StyledNavItems>
    </StyledNavBar>
  );
};

export default Navbar;
