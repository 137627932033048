import { Navigate, useLocation } from 'react-router-dom';

import { useAuthHelper } from '../hooks/useAuthHelper';

const DEFAULT_AUTHENTICATED_PATH = '/dashboard';

interface Props {
  children: JSX.Element;
}

export default function RequireNotAuth({ children }: Props): JSX.Element {
  const { user } = useAuthHelper();
  const location = useLocation();
  return user ? (
    <Navigate
      to={{
        pathname: DEFAULT_AUTHENTICATED_PATH,
      }}
      state={{ from: location }}
    />
  ) : (
    children
  );
}
