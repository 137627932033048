import { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSHeading, ADSInput, ADSPage, ADSText } from '@aphrodite/common-ui';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: auto;
  padding-top: 8%;
  @media only screen and (min-width: 680px) {
    padding: 0 4%;
    padding-top: 8%;
  }
`;

const StyledHeader = styled(ADSHeading)`
  hyphens: none;
  line-height: 110%;
  margin: 4% 0%;
  display: table-caption;
  font-weight: 500;
`;

const StyledTextChuck = styled(ADSText)`
  margin: 4% 0%;
  hyphens: none;
  text-decoration: none;
`;

const StyledInputSubmit = styled.div`
  margin-top: 2%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  gap: 4%;
`;
const StyledButton = styled(ADSButton)`
  margin: 4% 0%;
  max-width: 100%;
  width: 60%;
  background-color: #e82c3a;
`;

const StyledMailToText = styled(ADSAnchor)`
  margin: 18% 0%;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  @media only screen and (min-width: 680px) {
    margin: 8% 0%;
  }
`;

// Main Component
interface Props {
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  projectName: string;
  isEmailValid: boolean;
  isLoading: boolean;
  value: string;
}

export default function EmailPage({
  onEmailChange,
  onSubmit,
  projectName,
  isEmailValid,
  isLoading,
  value,
}: Props): React.ReactElement {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  return (
    <ADSPage>
      <StyledContainer>
        <StyledHeader level="1">Aphrodite Internal</StyledHeader>
        <StyledTextChuck>
          {'You will have to request for a new link here each time you sign in.'}
        </StyledTextChuck>
        <StyledInputSubmit>
          <ADSInput
            onChange={onEmailChange}
            onKeyPress={handleKeyPress}
            placeholder={'example@email.com'}
            isValid={isEmailValid}
            value={value}
            wrapperStyle={{ margin: '4% 0%' }}
          />
          <StyledButton loading={isLoading} onClick={onSubmit}>
            Continue
          </StyledButton>
        </StyledInputSubmit>
        <StyledMailToText to="mailto:cupids@aphrodite.global">
          <ADSText color="darkGrey" textDecoration="underline" size="s">
            {`Having difficultes with your email? Message the team!`}
          </ADSText>
        </StyledMailToText>
      </StyledContainer>
    </ADSPage>
  );
}
