import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'regenerator-runtime/runtime';

import { getAnalytics, logEvent, setCurrentScreen } from '@firebase/analytics';

import RequireAuth from '../components/RequireAuth';
import RequireNotAuth from '../components/RequireNotAuth';
import PageConfirmEmail from './PageConfirmEmail';
import PageDashboard from './PageDashboard';
import PageEmailVerification from './PageEmailVerification';
import PageLogout from './PageLogout';
import PageNotFound from './PageNotFound';
import PageSingleSends from './PageSingleSends';

export default function Root(): React.ReactElement {
  const analytics = getAnalytics();
  const location = useLocation();
  useEffect(() => {
    // Listen for react browser router changes and update
    window.scrollTo(0, 0);
    setCurrentScreen(analytics, location.pathname);
    logEvent(analytics, 'page-view', { path_name: location.pathname });
  }, [analytics, location]);
  return (
    <>
      <Routes>
        <Route
          element={
            <RequireNotAuth>
              <PageEmailVerification />
            </RequireNotAuth>
          }
          path="/"
        />
        <Route
          element={
            <RequireNotAuth>
              <PageConfirmEmail />
            </RequireNotAuth>
          }
          path="/confirm"
        />
        <Route
          element={
            <RequireNotAuth>
              <PageEmailVerification />
            </RequireNotAuth>
          }
          path="/start"
        />
        <Route
          element={
            <RequireAuth>
              <PageDashboard />
            </RequireAuth>
          }
          path="/dashboard/*"
        />
        <Route
          element={
            <RequireAuth>
              <PageSingleSends />
            </RequireAuth>
          }
          path="/single-sends"
        />
        <Route element={<PageLogout />} path="/logout" />
        <Route element={<PageNotFound />} path="*" />
      </Routes>
    </>
  );
}
