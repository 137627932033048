import { ChangeEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';

import UNIVERSITY_CONSTANTS, { CollegesEnum } from '@aphrodite/common/constants/College';

import Navbar from '../components/Navbar';
import { useAuthHelper } from '../hooks/useAuthHelper';
import EmailPage from './EmailPage';
import MagicLinkInfo from './MagicLinkInfo';

export default function PageEmailVerification(): React.ReactElement {
  const { sendSignInLinkToEmail } = useAuthHelper();
  const { pathname } = useLocation();
  const uniKeyString = pathname.split('/')[2];
  const uniKey = uniKeyString as CollegesEnum;
  const schoolName = UNIVERSITY_CONSTANTS.COLLEGE_NAMES[uniKey];
  let projectName = schoolName;
  if (projectName === 'Harvard' || projectName === 'MIT') {
    projectName = 'MITxHarvard';
  }
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail((e.target.value as string).toLowerCase());
  };
  const onSubmitEmail = async () => {
    if (isLoading) {
      return; // Don't submit twice.
    }
    setEmailValid(true);
    setIsLoading(true);
    // Check if the user actually typed in an email
    if (!UNIVERSITY_CONSTANTS.EMAIL_REGEX.test(email)) {
      setEmailValid(false);
      setIsLoading(false);
      return;
    }
    try {
      await sendSignInLinkToEmail(email);
      setIsLoading(false);
    } catch (error) {
      setEmailValid(false);
      setIsLoading(false);
      return;
    }
    // Valid email entered
    // Sign out the current user, and sign this user in
    setConfirmEmail(true);
  };
  return (
    <>
      <Navbar />
      {!confirmEmail ? (
        <EmailPage
          onEmailChange={onEmailChange}
          onSubmit={onSubmitEmail}
          projectName={projectName}
          isEmailValid={isEmailValid}
          isLoading={isLoading}
          value={email}
        />
      ) : (
        <MagicLinkInfo email={email} />
      )}
    </>
  );
}
