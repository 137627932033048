import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthHelper } from '../hooks/useAuthHelper';
import PageLoading from './PageLoading';

export default function PageConfirmEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signInWithEmailLink } = useAuthHelper();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await signInWithEmailLink(location.search);
      } catch (error) {
        alert(
          `An error occured: ${error}. We have to redirect you to reconfirm your email address.`,
        );
        navigate('/start');
      }
    };
    confirmEmail();
  }, [navigate, location.search, signInWithEmailLink]);
  return <PageLoading />;
}
