import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ADSPage } from '@aphrodite/common-ui';

import DashboardMenuBarDesktop from '../components/dashboard/DashboardMenuBarDesktop';
import DashboardMenuBarMobile from '../components/dashboard/DashboardMenuBarMobile';
import DashboardSingleSends from '../components/singlesends/DashboardSingleSends';
import useIsMobile from '../hooks/useIsMobile';

// Styled Component

export default function PageSingleSends(): React.ReactElement {
  const isMobile = useIsMobile('TABLET');
  const pathname = useLocation().pathname;
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);

  return (
    <ADSPage>
      {isMobile ? <DashboardMenuBarMobile /> : <DashboardMenuBarDesktop />}
      <DashboardSingleSends />
    </ADSPage>
  );
}
