import { MenuItem, Select, Slider } from '@mui/material';
import dayjs from 'dayjs';
import { collection, orderBy, query } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useFirestoreCollectionData } from 'reactfire';
import styled from 'styled-components';

import { ADSHeading, ADSText } from '@aphrodite/common-ui';
import { SimpleBarChart, SimplePieChart } from '@aphrodite/common-ui/graphs';
import { UniData } from '@aphrodite/common/types/DataReportTypes';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';

import { objectDataToGraphFormat } from '../../helpers/dataTransform';
import DashboardLoading from './DashboardLoading';

const StyledContentsContainer = styled.main`
  display: flex;
  max-width: 100%;
  padding: 0 8%;
  padding-top: 20%;
  padding-bottom: 10%;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    margin-top: ${({ theme }) => `${parseFloat(theme.spacing.navbarDesktopLogoHeight) * 0.25}px`};
    margin-left: ${({ theme }) => theme.spacing.navbarDesktopWidth};
    padding: 0 4%;
    grid-template-columns: 3fr 2fr;
  }
`;
const StyledTitleContainer = styled.div`
  margin-bottom: 12px;
`;
const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: left;
`;

export default function DashboardRouteMain(): React.ReactElement {
  const year = new Date().getFullYear();
  const [dateIndex, setDateIndex] = useState(0);
  const [selectedUni, setSelectedUni] = useState('');
  const [uniData, setUniData] = useState<any>();
  const statisticsCollection = collection(firebaseFirestore, `Internal/Statistics/${year}`);
  const statisticsQuery = query(statisticsCollection, orderBy('date'));
  const { status, data: rawData } = useFirestoreCollectionData(statisticsQuery);
  useEffect(() => {
    if (!rawData || rawData.length === 0) {
      return;
    }
    console.log(rawData);
    setUniData(rawData);
    setSelectedUni(rawData[0]['uniData'][0]['university']);
  }, [rawData]);
  const handleDateChange = useCallback((_: any, value: number | number[]) => {
    setDateIndex(value as number);
  }, []);
  if (status === 'loading' || !uniData) {
    return <DashboardLoading />;
  }
  const currentDate = dayjs(uniData[dateIndex]['date']);
  const readableDate = currentDate.format('DD MMM YYYY, dddd: hh:mm A');
  const universities = uniData[dateIndex].uniData.map((uni: any) => uni['university']);
  const uniDataMap: { [key: string]: UniData } = {};
  uniData[dateIndex]['uniData'].forEach((data: UniData) => {
    uniDataMap[data['university'] as string] = data;
  });
  const selectedUniData = uniDataMap[selectedUni] as UniData;
  const genderItems = objectDataToGraphFormat(selectedUniData, 'gender');
  const sexualItems = objectDataToGraphFormat(selectedUniData, 'sexualOrientation');
  const yearItems = objectDataToGraphFormat(selectedUniData, 'year');
  const majorItems = objectDataToGraphFormat(selectedUniData, 'major');
  return (
    <StyledContentsContainer>
      <StyledTitleContainer>
        <ADSHeading level="2">Internal Dashboard for {year}</ADSHeading>
        <ADSText>Updated every 4 hours</ADSText>
      </StyledTitleContainer>
      <StyledHorizontalContainer>
        <ADSHeading level="3">University:</ADSHeading>
        <Select
          value={selectedUni}
          defaultValue={uniData[dateIndex]['uniData'][0]['university']}
          onChange={(e) => {
            setSelectedUni(e.target.value);
          }}
          style={{
            marginLeft: '16px',
          }}
        >
          {universities.map((name: string, _: number) => {
            return (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledHorizontalContainer>
      <br />
      <ADSHeading level="3">Data as of – {readableDate}</ADSHeading>
      <ADSText size="m">Timezone: UTC {currentDate.format('ZZ')}</ADSText>
      <Slider
        key="date key"
        defaultValue={dateIndex}
        step={1}
        min={0}
        max={uniData.length - 1}
        onChange={handleDateChange}
        value={dateIndex}
      />
      <br />
      <StyledHorizontalContainer>
        <ADSHeading level="3">
          Total Sign-ups (including incomplete): {selectedUniData['totalCount']}
        </ADSHeading>
      </StyledHorizontalContainer>
      <hr />
      <br />
      <SimpleBarChart
        dataKeys={['count']}
        title="Gender"
        type="bar"
        graphType="bar"
        items={genderItems}
      />
      <SimpleBarChart
        dataKeys={['count']}
        title="Sexual Orientation"
        type="bar"
        graphType="bar"
        items={sexualItems}
      />
      <SimpleBarChart
        dataKeys={['count']}
        title="Year of Study"
        type="bar"
        graphType="bar"
        items={yearItems}
      />
      <SimplePieChart
        dataKeys={['count']}
        title="Majors"
        type="pie"
        graphType="pie"
        items={majorItems}
      />
    </StyledContentsContainer>
  );
}
